import App from "./App";
import React from "react";

export default function Root() {
  return (
    <section>
      <App />
    </section>
  );
}

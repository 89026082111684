// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dmn-decision-table-container {
    display: flex;
    justify-content: center;
    padding-top: 1%;
}

.dmn-icon-decision-table {
    transform: translate(0px, 20px);
}

.viewport {
    transform: translate(0px, 20px);
}

.drd-modeler-container {
    width: 70%;
    height: 90vh;
}

.drd-properties-panel {
    width: 30%;
    height: 92vh;
    border: 1px solid #ccc;
}

.dmn-modeler-container {
    width: 100%;
    height: 85vh;
}

.dmn-properties-panel {
    width: 0;
}

.flexbox {
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/styles/custom-dmn-styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".dmn-decision-table-container {\n    display: flex;\n    justify-content: center;\n    padding-top: 1%;\n}\n\n.dmn-icon-decision-table {\n    transform: translate(0px, 20px);\n}\n\n.viewport {\n    transform: translate(0px, 20px);\n}\n\n.drd-modeler-container {\n    width: 70%;\n    height: 90vh;\n}\n\n.drd-properties-panel {\n    width: 30%;\n    height: 92vh;\n    border: 1px solid #ccc;\n}\n\n.dmn-modeler-container {\n    width: 100%;\n    height: 85vh;\n}\n\n.dmn-properties-panel {\n    width: 0;\n}\n\n.flexbox {\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useRef, useState } from "react";
import DmnModeler from 'dmn-js/lib/Modeler.js';
import { DmnPropertiesPanelModule, DmnPropertiesProviderModule, CamundaPropertiesProviderModule } from 'dmn-js-properties-panel/dist/index.esm.js';
import MarketplacePropertiesProvider from "../extensions/providers/marketplace";
import MarketplaceModdleDescriptor from "../extensions/descriptors/ApiDetails.json";
import 'dmn-js/dist/assets/diagram-js.css';
import 'dmn-js/dist/assets/dmn-js-shared.css';
import 'dmn-js/dist/assets/dmn-js-decision-table-controls.css';
import 'dmn-js/dist/assets/dmn-js-decision-table.css';
import 'dmn-js/dist/assets/dmn-js-drd.css';
import 'dmn-js/dist/assets/dmn-js-literal-expression.css';
import 'dmn-js/dist/assets/dmn-font/css/dmn.css';
import '../styles/custom-dmn-styles.css';
import "../styles/dmn-button-bar.css";
import DmnButtonBar from "./DmnButtonBar";
import 'dmn-js-properties-panel/dist/assets/properties-panel.css';
import Alert from "@mui/material/Alert";
import axios from "axios";
import Dialog, {DialogProps} from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { saveAs } from 'file-saver';

function DmnEditor({defaultXml}) {
  const modelerContainer = useRef(null);
  const modelerRef = useRef(null);
  const propertiesPanel = useRef(null);
  const [modelerClass, setModelerClass] = useState("drd-modeler-container");
  const [propertiesPanelClass, setPropertiesPanelClass] = useState("drd-properties-panel");
  const [dmnfile, setDmn] = useState(defaultXml);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [scroll] = useState<DialogProps['scroll']>('paper');
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);


  useEffect(() => {

      modelerRef.current = new DmnModeler({
      container: modelerContainer.current,
      drd: {
        propertiesPanel: {
          parent: propertiesPanel.current
        },
        additionalModules: [
          DmnPropertiesPanelModule,
          DmnPropertiesProviderModule,
          CamundaPropertiesProviderModule,
          MarketplacePropertiesProvider
        ]
      },
      moddleExtensions: {
        apiDetails: MarketplaceModdleDescriptor
      }
    });
    importXml(dmnfile);
    return () => {
        destroyListeners();
        modelerRef.current.destroy();
        modelerContainer.current = null;
    };
  }, []);

  useEffect(() => {
    if(dmnfile !== null) {
      importXml(dmnfile);
    }
      return () => {
          destroyListeners();
      };
    }, [dmnfile])

    const destroyListeners = () => {
        modelerRef.current._eventBus.off('drillDown.click', handleDmnClick);
        modelerRef.current._eventBus.off('import.done', handleDrdClick);
    }

    const handleDmnClick = () => {
        setModelerClass("dmn-modeler-container");
        setPropertiesPanelClass("dmn-properties-panel");
    }

    const handleDrdClick = () => {
        setModelerClass("drd-modeler-container");
        setPropertiesPanelClass("drd-properties-panel");
    }

 const saveEditedTable = () => {
   return new Promise((resolve, reject) => {
     modelerRef.current.saveXML({ format: true }, function (err, xml) {
       if (err) {
         reject(err);
       } else {
         resolve(xml);
       }
     });
   });
  }

  const importXml = (dmnXml) => {
      modelerRef.current.importXML(dmnXml, (err) => {
          if (err) {
              console.error("error while importing dmn model, ", err)
          } else {
              const activeViewer = modelerRef.current.getActiveViewer();
              const eventBus = activeViewer.get('eventBus');
              eventBus.on('drillDown.click', () => setModelerClass("dmn-modeler-container"));
              eventBus.on('import.done', () => setModelerClass("drd-modeler-container"));
          }
      });
  }

  const downloadFile = () => {
      saveEditedTable().then((xml) => {
          let tableName = modelerRef.current.getDefinitions().id;
          setDmn(xml);
          let xmlCode = String(xml);
          const file = new Blob([xmlCode], {type: 'text/plain'});
          saveAs(file, tableName + '.dmn' || 'dmnTable.dmn');
      })
          .catch((err) => {
              console.error('Error When Downloading DMN File:', err);
          });
  }

  const deployDmn = () => {
    saveEditedTable().then((xml) => {
      setDmn(xml);
      let xmlCode = String(xml);
      let url = 'https://arbiter.dev.bnymellon.net/arbiter-prime/deployments';
      const file = new Blob([xmlCode], {type: 'text/plain'});
      let formData = new FormData();
      let data = {
        "category": "string"
      };
      formData.append("data", JSON.stringify(data));
      formData.append("file", file);
      axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then((response) => {
        if (response.status === 201) {
            handleDeployAlert(true, true);
        }
      }).catch(() => {
          handleDeployAlert(true, false);
      });
    })
        .catch((err) => {
          console.error('Error:', err);
        });
  };

  const handleUploadDmn = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            setUploadedFile(e.target.result);
        };
        reader.readAsText(file);
        handleClickOpenFileDialog();
  };

    const openXmlCode = () => {
        saveEditedTable().then((xml) => {
            setDmn(xml);
            setOpen(true);
        }).catch((err) => {
            console.error('Error whilst saving the current DMN table changes:', err);
        });
    };

    const handleCloseXML = () => {
        setOpen(false);
    };

  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const {current: descriptionElement} = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleClickOpenFileDialog = () => {
    setOpenFileDialog(true);
  };

  const handleCloseFileDialogYes = () => {
    setOpenFileDialog(false);
    setDmn(uploadedFile);
      setUploadedFile(null);
  };

  const handleCloseFileDialogNo = () => {
    setOpenFileDialog(false);
    setUploadedFile(null);
  };

  const handleDeployAlert = (openStatus, responseMessage) => {
    setOpenAlert(openStatus);
    setAlertSuccess(responseMessage);
    setTimeout(() => setOpenAlert(false), 3000);
  };

  return (
      <div>
        {(openAlert && alertSuccess) && (
            <Alert severity="success">
              DMN File Deployed Successfully.
            </Alert>
        )}
        {(openAlert && !alertSuccess) && (
            <Alert severity="error">
              DMN File Failed to Deploy.
            </Alert>
        )}
          <div className="flexbox">
            <div className={modelerClass} ref={modelerContainer}></div>
            <div className={propertiesPanelClass} ref={propertiesPanel}></div>
          </div>
        <DmnButtonBar deploy={deployDmn} downloadFile={downloadFile} openXmlCode={openXmlCode} handleUpload={handleUploadDmn}></DmnButtonBar>
        <React.Fragment>
          <Dialog
              maxWidth='lg'
              open={open}
              onClose={handleCloseXML}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">XML Code</DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
              <DialogContentText
                  id="scroll-dialog-description"
                  ref={descriptionElementRef}
                  tabIndex={-1}
                  className="xml-code-style"
              >
                 {dmnfile}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseXML}>Close</Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
        <React.Fragment>
          <Dialog
              open={openFileDialog}
              keepMounted
              onClose={handleCloseFileDialogNo}
              aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Upload File and Overwrite changes?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Uploading a DMN file will overwrite any changes made in the current editor.
                Do you wish to upload a file and overwrite these changes?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseFileDialogNo}>No</Button>
              <Button onClick={handleCloseFileDialogYes}>Yes</Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </div>
  );
}

export default DmnEditor;
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import DownloadIcon from "@mui/icons-material/Download";
import CodeIcon from "@mui/icons-material/Code";
import React, {useRef} from "react";
import "../styles/dmn-button-bar.css";
import UploadIcon from '@mui/icons-material/Upload';

function DmnButtonBar({deploy, downloadFile, openXmlCode, handleUpload}) {
    const buttonsContainerRef = useRef(null);
    const fileInputRef = useRef(null);


    const deployClicked = () => {
        deploy();
    }

    const downloadClicked = () => {
        downloadFile();
    }

    const viewXmlClicked = () => {
        openXmlCode();
    }

    const uploadClicked = (e) => {
        handleUpload(e);
    }


    return (
        <div>
            <div className="button-bar-margin">
                <div ref={buttonsContainerRef} className="button-container">
                    <div className="button-spacer">
                        <button onClick={deployClicked} className="dmn-icon-button" title="Deploy DMN">
                            <RocketLaunchIcon></RocketLaunchIcon>
                        </button>
                    </div>
                    <div className="button-spacer">
                        <button onClick={downloadClicked} className="dmn-icon-button" title="Download DMN File">
                            <DownloadIcon></DownloadIcon>
                        </button>
                    </div>
                    <div className="button-spacer">
                        <button onClick={viewXmlClicked} className="dmn-icon-button" title="View XML Code">
                            <CodeIcon></CodeIcon>
                        </button>
                    </div>
                    <div className="button-spacer">
                        <button onClick={() => fileInputRef.current.click()} className="dmn-icon-button"
                        title="Upload DMN File to Editor">
                            <UploadIcon></UploadIcon>
                        </button>
                        <input
                            ref={fileInputRef}
                            type="file"
                            onChange={uploadClicked} hidden/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DmnButtonBar;

import React from "react";
import DmnEditor from "./components/DmnEditor";
import "./styles/header.css";


function App() {

    const xml = `<?xml version="1.0" encoding="UTF-8"?>
        <definitions xmlns="https://www.omg.org/spec/DMN/20191111/MODEL/" xmlns:dmndi="https://www.omg.org/spec/DMN/20191111/DMNDI/" xmlns:dc="http://www.omg.org/spec/DMN/20180521/DC/" xmlns:biodi="http://bpmn.io/schema/dmn/biodi/2.0" xmlns:camunda="http://camunda.org/schema/1.0/dmn" id="document_request_tab" name="Document Request Tab" namespace="http://camunda.org/schema/1.0/dmn" exporter="Camunda Modeler" exporterVersion="5.9.0">
          <decision id="document_request_tab_setup" name="Document Request Tab DMN" camunda:versionTag="DOCUMENT_REQUEST" camunda:historyTimeToLive="7">
            <decisionTable id="DecisionTable_1kkrddr" hitPolicy="COLLECT">
              <input id="InputClause_0er1h59" label="Region">
                <inputExpression id="LiteralExpression_1hhyjni" typeRef="string">
                  <text>region</text>
                </inputExpression>
              </input>
              <output id="OutputClause_0zd59n2" label="Product Package Name" name="productPackageName" typeRef="string" biodi:width="277" />
              <output id="OutputClause_1fherd1" label="File Name" name="fileName" typeRef="string" />
              <rule id="DecisionRule_0lqutk1">
                <inputEntry id="UnaryTests_0b4e4yh">
                  <text></text>
                </inputEntry>
                <outputEntry id="LiteralExpression_1l74hs8">
                  <text>"document_request/global/"</text>
                </outputEntry>
                <outputEntry id="LiteralExpression_1jjj38k">
                  <text>"document-request-tabs.json"</text>
                </outputEntry>
              </rule>
            </decisionTable>
          </decision>
          <dmndi:DMNDI>
            <dmndi:DMNDiagram>
              <dmndi:DMNShape dmnElementRef="document_request_tab_setup">
                <dc:Bounds height="80" width="180" x="160" y="100" />
              </dmndi:DMNShape>
            </dmndi:DMNDiagram>
          </dmndi:DMNDI>
        </definitions> `;
    return (
        <div>
            <DmnEditor defaultXml={xml} />
        </div>

    )
}

export default App;
import marketplaceApiProps from './props/MarketplaceApiProps';
import { is } from 'dmn-js-shared/lib/util/ModelUtil';

export default function MarketplacePropertiesProvider(propertiesPanel, translate) {

  this.getGroups = function(element) {
    return function(groups) {
       
      if (is(element, 'dmn:InputData')) {
        groups.push(createMarketplaceApiDetailsGroup(element, translate));
      }
      return groups;
    };
  };

    propertiesPanel.registerProvider(500, this);

}

MarketplacePropertiesProvider.$inject = [ 'propertiesPanel', 'translate' ];

function createMarketplaceApiDetailsGroup(element, translate) {

    const MarketplaceApiDetailsGroup = {
      id: 'apiDetails',
      label: translate('Marketplace API Details'),
      entries: marketplaceApiProps(element)
    };
  
    return MarketplaceApiDetailsGroup;

}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.module-items {
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.module-icon-alignment {
    display: flex;
    padding-top: 12px;
}

.dmn-icon-button {
    background-color: #FAFAFA;
    border: 2PX solid #dedede;
    padding: 6px;
    border-radius: 3px;
}

.button-bar-margin{
    margin-left: 1rem;
}

.dmn-icon-button:hover  {
    background-color: rgb(227, 235, 237);
}

.modal-box-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    border: 2px solid #000;
    background-color: white;
    padding: 20px;
}

.button-container {
    display: flex;
    position: fixed;
    flex-direction: row;
    bottom: 20px;
}

.diagram-container {
    position: relative;
    height: 90vh;
    width: 100%;
}

.button-spacer {
    padding: 2px;

}

.spacing {
    padding-left: 42px;
}

.xml-code-card {
    padding: 10px;
    margin: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.xml-code-style {
    white-space: pre-wrap;
    height: 100vh;
    width: 100vh;
}


`, "",{"version":3,"sources":["webpack://./src/styles/dmn-button-bar.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,YAAY;IACZ,sBAAsB;IACtB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;;AAEhB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,YAAY;AAChB","sourcesContent":[".custom-file-upload {\n    display: inline-block;\n    padding: 6px 12px;\n    cursor: pointer;\n}\n\n.module-items {\n    display: flex;\n    flex-direction: row;\n    padding: 10px;\n}\n\n.module-icon-alignment {\n    display: flex;\n    padding-top: 12px;\n}\n\n.dmn-icon-button {\n    background-color: #FAFAFA;\n    border: 2PX solid #dedede;\n    padding: 6px;\n    border-radius: 3px;\n}\n\n.button-bar-margin{\n    margin-left: 1rem;\n}\n\n.dmn-icon-button:hover  {\n    background-color: rgb(227, 235, 237);\n}\n\n.modal-box-style {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 400px;\n    border: 2px solid #000;\n    background-color: white;\n    padding: 20px;\n}\n\n.button-container {\n    display: flex;\n    position: fixed;\n    flex-direction: row;\n    bottom: 20px;\n}\n\n.diagram-container {\n    position: relative;\n    height: 90vh;\n    width: 100%;\n}\n\n.button-spacer {\n    padding: 2px;\n\n}\n\n.spacing {\n    padding-left: 42px;\n}\n\n.xml-code-card {\n    padding: 10px;\n    margin: 40px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n}\n\n.xml-code-style {\n    white-space: pre-wrap;\n    height: 100vh;\n    width: 100vh;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

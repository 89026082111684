import { html } from 'htm/preact';
import { TextFieldEntry, isTextFieldEntryEdited, TextAreaEntry, isTextAreaEntryEdited} from '@bpmn-io/properties-panel';
import { useService } from 'dmn-js-properties-panel';

export default function(element) {

  return [
    {
      id: 'apiRequestMethod',
      element,
      component: textField,
      isEdited: isTextFieldEntryEdited,
      fieldDescription: '',
      fieldLabel: "API Request Method"
    },
    {
      id: 'apiContext',
      element,
      component: textField,
      isEdited: isTextFieldEntryEdited,
      fieldDescription: '',
      fieldLabel: "API Context"
    },
    {
      id: 'version',
      element,
      component: textField,
      isEdited: isTextFieldEntryEdited,
      fieldDescription: '',
      fieldLabel: "API Version"
    },
    {
      id: 'path',
      element,
      component: textField,
      isEdited: isTextFieldEntryEdited,
      fieldDescription: '',
      fieldLabel: "API Path"
    },
    {
      id: 'environment',
      element,
      component: textField,
      isEdited: isTextFieldEntryEdited,
      fieldDescription: '',
      fieldLabel: "API Environment"
    },
    {
      id: 'requestBody',
      element,
      component: requestBody,
      isEdited: isTextAreaEntryEdited,
      fieldDescription: 'Request paramaters to fetch dmn inputs from external systems',
      fieldLabel: "Request Body"
    }
  ];
}

function textField(props) {
  const { element, id, fieldDescription, fieldLabel } = props;

  const modeling = useService('modeling');
  const translate = useService('translate');
  const debounce = useService('debounceInput');

  const getValue = () => {
    return element.businessObject[id] || '';
  };

  const setValue = value => {
    return modeling.updateProperties(element, {
      [id]: value
    });
  };

  return html`<${TextFieldEntry}
    id=${ id }
    element=${ element }
    description=${ translate(fieldDescription) }
    label=${ translate(fieldLabel) }
    getValue=${ getValue }
    setValue=${ setValue }
    debounce=${ debounce }
  />`;
}

function  requestBody(props) {
  const { element, id, fieldDescription, fieldLabel } = props;

  const modeling = useService('modeling');
  const translate = useService('translate');
  const debounce = useService('debounceInput');

  const getValue = () => {
    return element.businessObject[id] || '';
  };

  const setValue = value => {
    return modeling.updateProperties(element, {
      [id]: value
    });
  };

  return html`<${TextAreaEntry}
    id=${ id }
    element=${ element }
    description=${ translate(fieldDescription) }
    label=${ translate(fieldLabel) }
    getValue=${ getValue }
    setValue=${ setValue }
    debounce=${ debounce }
  />`;
}